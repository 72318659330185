body {
  margin: 0;
  font-family: "Source Sans Pro";
  scrollbar-width: thin;
  & ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 10px;
    background-color: #e9e8e8;
  }
}
body {
  scrollbar-width: thin;
}

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/*# sourceMappingURL=index.css.map */
